<template>
  <div
    class="text-center m-5 mx-auto text-sm flex flex-col gap-1 z-0"
  >
    <strong>Michael-Strain - © Strain Investment Co. LLC</strong>
    <div class="text-sm flex justify-center gap-8">
      <NuxtLink
        to="/contact"
        class=""
      >
        Contact Us
      </NuxtLink>
      <NuxtLink
        to="/shop/faqs"
      >
        Frequently Asked Questions
      </NuxtLink>
    </div>
    <p class="text-xs text-wrap">
      All content is owned solely by Michael Strain, and shall not be used or reproduced without prior written consent. All Rights Reserved.
    </p>
  </div>
</template>